// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-stories-js": () => import("./../../../src/pages/our-stories.js" /* webpackChunkName: "component---src-pages-our-stories-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-support-group-js": () => import("./../../../src/pages/support-group.js" /* webpackChunkName: "component---src-pages-support-group-js" */),
  "component---src-pages-transplant-info-js": () => import("./../../../src/pages/transplant-info.js" /* webpackChunkName: "component---src-pages-transplant-info-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

